import { Box, Button, Divider } from "@mui/material";
import { IconUser } from "../../assets";
import { memo } from "react";
import Logo from "../Logo";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  animatedItem: {
    animation: `$myEffect 1000ms ease-in-out`,
    animationFillMode: "both",
    animationIterationCount: "infinite",
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0.2,
    },
    "50%": {
      opacity: 0.5,
    },
    "100%": {
      opacity: 0.2,
    },
  },
}));

const iconSx = {
  width: 30,
  height: 30,
  flexShrink: 0,
};

function MessageLine(line, key) {
  const boldRegex = /\*\*(.*?)\*\*/g;
  const parts = line.split(boldRegex);

  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = <b key={i}>{parts[i]}</b>;
  }

  return line ? <p key={key}>{parts}</p> : <br key={key} />;
}

function ChatBotMessage({ message, rendering }) {
  const styles = useStyles();

  return (
    <Box
      display="flex"
      gap={2}
      p={2}
      bgcolor={message.role === "human" ? undefined : "#f4f4f4"}
    >
      {message.role === "human" ? (
        <IconUser sx={iconSx} />
      ) : (
        <div className={rendering ? styles.animatedItem : undefined}>
          <Logo src="/logo/logo_single.svg" sx={iconSx} disabledLink />
        </div>
      )}
      <Box>
        <Box fontSize={14}>{message.content.split("\n").map(MessageLine)}</Box>
        {message.actions && (
          <>
            <Divider sx={{ my: 1 }} />
            {message.actions.map((action, idx) => (
              <Button
                key={`${action.label}-${idx}`}
                href={action.href}
                target="_blank"
                variant="outlined"
                color="info"
                p={1}
                mt={1}
              >
                {action.label}
              </Button>
            ))}
          </>
        )}
      </Box>
    </Box>
  );
}

export default memo(ChatBotMessage);
