import { PATH_PAGE } from "../../routes/paths";
import Iconify from "../../components/Iconify";
import { useOutletContext } from "react-router-dom";

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const CurrentMarketplace = (marketplaceId, marketplaceName) => {
  let navConfig = [];

  // eslint-disable-next-line no-unused-vars
  // const [marketplace, marketplaceId, mainColor, accentColor] =
  //   useOutletContext();

  if (marketplaceId !== null && marketplaceId !== undefined && marketplaceName !== null && marketplaceName !== undefined) {
    navConfig = [
      {
        title: "Home",
        icon: (
          <Iconify
            icon={"material-symbols:home-outline-rounded"}
            {...ICON_SIZE}
          />
        ),
        path: marketplaceName !== "" ? PATH_PAGE.marketplaceView(marketplaceName) : PATH_PAGE.marketplaceView(marketplaceId),
      },
      {
        title: "Explore.",
        path: "",
        icon: (
          <Iconify
            icon={"material-symbols:saved-search-rounded"}
            {...ICON_SIZE}
          />
        ),
        children: [
          {
            subheader: "Explore",
            items: [
              {
                title: "Collections",
                path: marketplaceName !== "" ? PATH_PAGE.allMarketplaceCollections(marketplaceName) : PATH_PAGE.allMarketplaceCollections(marketplaceId),
              },
              {
                title: "Offers",
                path: marketplaceName !== "" ? PATH_PAGE.allMarketplaceOffers(marketplaceName) : PATH_PAGE.allMarketplaceOffers(marketplaceId),
              },
            ],
          },
        ],
      },
    ];
  }

  
  return navConfig;
};

export default CurrentMarketplace;
