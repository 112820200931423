import React, { memo, useEffect, useRef, useState } from "react";
import { Box, Button, Divider, InputBase } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChatBotMessage from "./ChatBotMessage";
import { IconArrowElbow } from "../../assets";
import ChatBotHeader from "./ChatBotHeader";
import { useChatContext } from "../../contexts/ChatContext";

const useStyles = makeStyles(() => ({
  mainWrapper: { height: "100%", display: "flex", flexDirection: "column" },
  scrollableContainer: {
    flexGrow: 1,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
}));

export default function ChatBotView({ onClose }) {
  const { socket, messages, setMessages } = useChatContext();

  const [input, setInput] = useState("");

  const [response, setResponse] = useState();
  const responseRef = useRef(null);

  const chatScroll = useRef();

  const classes = useStyles();

  useEffect(() => {
    socket.current.connect();

    socket.current = socket.current.on("connect", async () => {
      socket.current.on("stream", async (data) => {
        const startegy = {
          start: async () => {
            responseRef.current = { role: "ai", content: "" };
            setResponse(responseRef.current);
          },
          pending: async () => {
            responseRef.current = {
              role: "ai",
              content: responseRef.current.content.concat(data.content),
              actions: data.actions,
            };
            setResponse(responseRef.current);
          },
          end: async () => {
            setMessages((msg) => [
              ...msg,
              {
                ...responseRef.current,
                actions: data.actions,
              },
            ]);

            responseRef.current = null;
            setResponse(responseRef.current);
          },
        };

        await startegy[data.status]();
      });

      // Get History
      const history = await socket.current.emitWithAck("get_history");
      setMessages(history);
    });

    return () => {
      socket.current.disconnect();
    };
  }, []);

  const scrollToBottom = () => {
    chatScroll.current.scrollTo({
      top: chatScroll.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();

    setMessages([...messages, { role: "human", content: input }]);
    setInput("");

    await socket.current.emit("chat", { prompt: input });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages, response]);

  return (
    <Box className={classes.mainWrapper}>
      <ChatBotHeader onClose={onClose} />

      <Box className={classes.scrollableContainer} ref={chatScroll}>
        {messages.map((message, index) => (
          <ChatBotMessage
            key={`${message}-${index}`}
            message={{ ...message }}
          />
        ))}
        {response && <ChatBotMessage message={response} rendering />}
      </Box>

      <div>
        <Divider />
        <form
          onSubmit={handleSubmit}
          style={{
            position: "relative",
            padding: 16,
          }}
        >
          <InputBase
            value={input}
            onChange={handleInputChange}
            placeholder="Type a message..."
            fullWidth
            sx={{
              paddingRight: "28px",
            }}
          />
          <SubmitButton />
        </form>
      </div>
    </Box>
  );
}

const SubmitButton = memo(() => {
  return (
    <Button
      type="submit"
      sx={{
        width: 28,
        height: 28,
        position: "absolute",
        right: 8,
        top: "calc(50% - 14px)",
        boxSizing: "border-box",
        minWidth: "0",
      }}
    >
      <IconArrowElbow
        sx={{
          width: 20,
          height: 20,
        }}
      />
    </Button>
  );
});
SubmitButton.displayName = "SubmitButton";
