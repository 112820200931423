import createAvatar from "../utils/createAvatar";
import Avatar from "./Avatar";
import axios from "../utils/axios";
import useIsMountedRef from "../hooks/useIsMountedRef";
import { useEffect, useCallback, useState } from "react";
import requestHeaders from "../utils/restClient";

export default function TeamAvatar({ ...other }) {
  const appHeaders = requestHeaders(localStorage.getItem("accessToken"));

  const teamId = localStorage.getItem("teamId");

  const teamName = localStorage.getItem("teamName");
  const teamLogo = localStorage.getItem("teamLogo");

  const isMountedRef = useIsMountedRef();

  const [downloadedTeamLogo, setDownloadedTeamLogo] = useState(null);

  const getTeamLogo = useCallback(async () => {
    if (
      //team !== null &&
      teamLogo !== "undefined" &&
      teamLogo !== undefined &&
      teamLogo !== "" &&
      teamLogo !== null
    ) {
      try {
        const postData = {
          bucketKey: teamLogo,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/teams/` +
            teamId +
            "/downloadTeamFile",
          postData,
          appHeaders
        );

        if (isMountedRef.current) {
          setDownloadedTeamLogo(response.data.url);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [isMountedRef, teamId, teamLogo, appHeaders]);

  useEffect(() => {
    setDownloadedTeamLogo(null);
    getTeamLogo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, teamLogo]);

  return (
    <Avatar
      src={downloadedTeamLogo}
      alt={teamName}
      color={downloadedTeamLogo ? "default" : createAvatar(teamName).color}
      {...other}
    >
      {createAvatar(teamName).name}
    </Avatar>
  );
}
