import { Outlet } from "react-router-dom";
import { Box, Container, Typography, Stack } from "@mui/material";
import MainHeader from "./MainHeader";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { SkeletonPostItem } from "src/components/skeleton";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import Image from "src/components/Image";
import { MotionContainer, varBounce } from "../../components/animate";
import { PageNotFoundIllustration } from "../../assets";
import { m } from "framer-motion";
import { styled } from "@mui/material/styles";

export const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function MainLayout() {
  const [marketplace, setMarketplace] = useState(null);
  const [logoHeader, setLogoHeader] = useState();
  const isMountedRef = useIsMountedRef();
  const [downloadedLogoImage, setDownloadedLogoImage] = useState(null);
  const [mainColor, setMainColor] = useState("#231f20");
  const [accentColor, setAccentColor] = useState("#231f20");

  const [error, setError] = useState(false);
  const [marketplaceId, setMarketplaceId] = useState(null);
  const [marketplaceName, setMarketplaceName] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [walletEmail, setWalletEmail] = useState(null);
  const [sig, setSig] = useState(null);

  async function getUrlMarketplaceId() {
    const urlArray = String(window.location.pathname).split("/");
    let urlMarketplaceId = urlArray[urlArray.indexOf("marketplaces") + 1];
    if (urlMarketplaceId.match(/^[0-9a-fA-F]{24}$/)){
      setMarketplaceId(urlMarketplaceId);
      //GET marketplace name by mid
      const response = await axios.post(
        //${process.env.REACT_APP_API_BASE_URL}
        `${process.env.REACT_APP_API_BASE_URL}/marketplaceurls/${urlMarketplaceId}/name`,
        {url: urlMarketplaceId}
      );
      setMarketplaceName(response.data.marketplaceName)
    } else {
      //GET mid by name
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/marketplaceurls`,
        {url: urlMarketplaceId}
      );
      setMarketplaceId(response.data.mid)
      setMarketplaceName(urlMarketplaceId)
    }
  }

  useEffect(() => {
    getUrlMarketplaceId(marketplaceId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (marketplaceId !== null && marketplaceId !== undefined) {
      getMarketplaceById(marketplaceId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplaceId]);

  //GET marketplace by ID - public endpoint
  const getMarketplaceById = useCallback(async (marketplaceId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/marketplaces/${marketplaceId}`
      );
      if (response.data.isActive === false) {
        throw new Error("Marketplace unpublished");
      }

      setMarketplace(response.data);
    } catch (error) {
      setError(true);
    }
  }, []);

  useEffect(() => {
    if (marketplaceId !== null && marketplaceId !== undefined) {
      getMarketplaceById(marketplaceId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplaceId]);

  useEffect(() => {
    if (marketplace !== null && marketplace !== undefined) {
      setLogoHeader(
        marketplace.components.filter(function (el) {
          return el.component === "Logo & Header Module";
        })
      );
      const settings = marketplace.components.filter(function (el) {
        return el.uuid === "settings";
      });
      if (settings[0] !== undefined) {
        const colorMain = settings[0].attributes.filter(function (el) {
          return el.key === "colorMain";
        });
        if (colorMain.length > 0) {
          setMainColor(colorMain[0].value);
        }
        const colorAccent = settings[0].attributes.filter(function (el) {
          return el.key === "colorAccent";
        });
        if (colorAccent.length > 0) {
          setAccentColor(colorAccent[0].value);
        }
      }
    }
  }, [marketplace]);

  const getLogoImage = async () => {
    if (logoHeader !== undefined) {
      if (logoHeader.length > 0) {
        const imageBkKeyValue = logoHeader[0].attributes.filter(function (el) {
          return el.key === "imageBk";
        });

        const imageBk = imageBkKeyValue[0].value;

        const postData = {
          bucketKey: imageBk,
        };

        if (
          imageBk !== "undefined" &&
          imageBk !== undefined &&
          imageBk !== "" &&
          imageBk !== null
        ) {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/downloadFile`,
              postData
            );

            if (isMountedRef.current) {
              setDownloadedLogoImage(response.data.url);
            }
          } catch (error) {
            console.error(error);
            setError(true);
          }
        }
      }
    }
  };

  useEffect(() => {
    getLogoImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoHeader]);

  return (
    <Stack sx={{ minHeight: 1 }}>
      {!error && (
        <>
          {marketplace !== undefined ? (
            <>
              {downloadedLogoImage !== undefined &&
                downloadedLogoImage !== null && (
                  <MainHeader
                    marketplaceId={marketplaceId}
                    marketplaceUrlName={marketplaceName}
                    component={logoHeader}
                    mainColor={mainColor}
                    accentColor={accentColor}
                    fileUrl={downloadedLogoImage}
                    marketplace={marketplace}
                    wallet={wallet}
                    setWallet={setWallet}
                    walletEmail={walletEmail}
                    setWalletEmail={setWalletEmail}
                    sig={sig}
                    setSig={setSig}
                  />
                )}
              {marketplaceId !== null && marketplaceId !== undefined && marketplaceName !== null && marketplaceName !== undefined && <Outlet
                context={[
                  marketplace,
                  marketplaceId,
                  mainColor,
                  accentColor,
                  wallet,
                  walletEmail,
                  sig,
                  setWallet,
                  setWalletEmail,
                  setSig,
                  marketplaceName,
                ]}
              />}
              <Box
                sx={{
                  py: 5,
                  textAlign: "center",
                  position: "relative",
                  bgcolor: "background.default",
                }}
              >
                <Container>
                  {downloadedLogoImage !== null &&
                    downloadedLogoImage !== undefined && (
                      <Image
                        alt="marketplace logo"
                        src={downloadedLogoImage}
                        sx={{ mb: 1, mx: "auto", width: 55, height: 55 }}
                      />
                    )}
                  <Typography variant="caption" component="p">
                    © All rights reserved
                  </Typography>
                </Container>
              </Box>
            </>
          ) : (
            <SkeletonPostItem></SkeletonPostItem>
          )}
        </>
      )}
      {error && (
        <Container component={MotionContainer}>
          <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                Oops, marketplace not found!
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography sx={{ color: "text.secondary" }}>
                Sorry, we couldn't find the marketplace you're looking for.
                Perhaps you've mistyped the URL? Be sure to check your spelling.
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <PageNotFoundIllustration
                sx={{ height: 260, my: { xs: 5, sm: 10 } }}
              />
            </m.div>
          </ContentStyle>
        </Container>
      )}
    </Stack>
  );
}
