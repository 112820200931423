import { useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  AppBar,
  Container,
  Typography,
  Menu,
  MenuItem,
  Stack,
  IconButton,
  Divider,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
import cssStyles from "../../../utils/cssStyles";
import { HEADER } from "../../../config";
import MenuDesktopPreview from "./MenuDesktopPreview";
import MenuMobile from "../MenuMobile";
import Image from "src/components/Image";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import CurrentMarketplace from "../MenuConfig";
import { ToolbarShadowStyle, ToolbarStyle } from "../MainHeader";

MainHeaderPreview.propTypes = {
  component: PropTypes.array,
};

export default function MainHeaderPreview({
  component,
  marketplaceId,
  mainColor,
  accentColor,
  fileUrl,
}) {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "md");

  const isHome = pathname === "/layout";

  const [marketplaceName, setMarketplaceName] = useState("");
  const [logoPosition, setLogoPosition] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  
  const ButtonStyle = styled(Button)(({ theme }) => ({
    ...theme.palette,
    textTransform: "capitalize",
    backgroundColor: accentColor,
    "&:hover": {
      backgroundColor: mainColor,
      color: "#ffffff",
    },
    color: "#ffffff",
  }));

  useEffect(() => {
    getHeaderData(component);
  }, [component, mainColor, accentColor, fileUrl]);

  function getHeaderData(component) {
    if (component !== undefined) {
      const name = component[0].attributes.filter(function (el) {
        return el.key === "name";
      });
      setMarketplaceName(name[0].value);
      const logoPlacement = component[0].attributes.filter(function (el) {
        return el.key === "logoPosition";
      });
      setLogoPosition(logoPlacement[0].value);
    }
  }

  return (
    <AppBar
      position="static"
      sx={{ boxShadow: 0, bgcolor: "transparent", mt: 0, mb: -5 }}
    >
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        {logoPosition === "left" && (
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Image
              alt="marketplace logo"
              src={fileUrl}
              sx={{ mb: 1, mt: 1, mx: "auto", width: 70, height: 70 }}
            />
            <Typography variant="subtitle1" color={mainColor} sx={{ ml: 1 }}>
              {marketplaceName}
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            {isDesktop && (
              <MenuDesktopPreview
                isOffset={isOffset}
                isHome={isHome}
                navConfig={CurrentMarketplace(marketplaceId, marketplaceName)}
                marketplaceId={marketplaceId}
                mainColor={mainColor}
                accentColor={accentColor}
              />
            )}

            <ButtonStyle variant="contained" target="_blank" rel="noopener">
              Enter
            </ButtonStyle>

            <IconButton sx={{ ml: 1 }} onClick={handleClick}>
              <Iconify
                icon={"material-symbols:account-circle"}
                width={25}
                height={25}
              />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <Stack direction="row">
                  <Typography>My Profile</Typography>
                </Stack>
              </MenuItem>
              <Divider />
              <MenuItem>
                <Stack direction="row">
                  <Typography>Logout</Typography>
                </Stack>
              </MenuItem>
            </Menu>

            {!isDesktop && (
              <MenuMobile
                isOffset={isOffset}
                isHome={isHome}
                navConfig={CurrentMarketplace(marketplaceId, marketplaceName)}
                fileUrl={fileUrl}
                mainColor={mainColor}
                accentColor={accentColor}
                marketplaceId={marketplaceId}
              />
            )}
          </Container>
        )}
        {logoPosition === "right" && (
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <ButtonStyle variant="contained" target="_blank" rel="noopener">
              Enter
            </ButtonStyle>
            <IconButton sx={{ ml: 1 }} onClick={handleClick}>
              <Iconify
                icon={"material-symbols:account-circle"}
                width={25}
                height={25}
              />
            </IconButton>
            {isDesktop && (
              <MenuDesktopPreview
                isOffset={isOffset}
                isHome={isHome}
                navConfig={CurrentMarketplace(marketplaceId, marketplaceName)}
                marketplaceId={marketplaceId}
                mainColor={mainColor}
                accentColor={accentColor}
              />
            )}

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <Stack direction="row">
                  <Typography>My Profile</Typography>
                </Stack>
              </MenuItem>
              <Divider />
              <MenuItem>
                <Stack direction="row">
                  <Typography>Logout</Typography>
                </Stack>
              </MenuItem>
            </Menu>
            <Box sx={{ flexGrow: 1 }} />
            <Typography variant="subtitle1" color={mainColor} sx={{ mr: 1 }}>
              {marketplaceName}
            </Typography>
            <Image
              alt="marketplace logo"
              src={fileUrl}
              sx={{ mb: 1, mt: 1, mx: "auto", width: 70, height: 70 }}
            />

            {!isDesktop && (
              <MenuMobile
                isOffset={isOffset}
                isHome={isHome}
                navConfig={CurrentMarketplace(marketplaceId, marketplaceName)}
                fileUrl={fileUrl}
                mainColor={mainColor}
                accentColor={accentColor}
                marketplaceId={marketplaceId}
              />
            )}
          </Container>
        )}

        {logoPosition === "center" && (
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {isDesktop && (
              <MenuDesktopPreview
                isOffset={isOffset}
                isHome={isHome}
                navConfig={CurrentMarketplace(marketplaceId, marketplaceName)}
                marketplaceId={marketplaceId}
                mainColor={mainColor}
                accentColor={accentColor}
              />
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Image
              alt="marketplace logo"
              src={fileUrl}
              sx={{ mb: 1, mt: 1, mx: "auto", width: 70, height: 70 }}
            />
            <Box sx={{ flexGrow: 1 }} />
            <ButtonStyle variant="contained" target="_blank" rel="noopener">
              Enter
            </ButtonStyle>
            <IconButton sx={{ ml: 1 }} onClick={handleClick}>
              <Iconify
                icon={"material-symbols:account-circle"}
                width={25}
                height={25}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <Stack direction="row">
                  <Typography>My Profile</Typography>
                </Stack>
              </MenuItem>
              <Divider />
              <MenuItem>
                <Stack direction="row">
                  <Typography>Logout</Typography>
                </Stack>
              </MenuItem>
            </Menu>

            {!isDesktop && (
              <MenuMobile
                isOffset={isOffset}
                isHome={isHome}
                navConfig={CurrentMarketplace(marketplaceId, marketplaceName)}
                fileUrl={fileUrl}
                mainColor={mainColor}
                accentColor={accentColor}
                marketplaceId={marketplaceId}
              />
            )}
          </Container>
        )}
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
