import jwtDecode from "jwt-decode";
import { PATH_AUTH } from "../routes/paths";
import axios from "./axios";

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp, openModalFunc) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  //const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;
  const fiveMins = 300000;

  if (timeLeft < fiveMins ) {
    openModalFunc(true);
  }

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Session expired. Please login again.');

    localStorage.removeItem("accessToken");
    localStorage.removeItem("_id");
    localStorage.removeItem("teamId");
    localStorage.removeItem("assignedTeamId");
    localStorage.removeItem("teamName");
    localStorage.removeItem("assignedTeamName");
    localStorage.removeItem("teamLogo");
    localStorage.removeItem("permissions");
    localStorage.removeItem("roleName");
    localStorage.removeItem("profileImage");
    localStorage.removeItem("fullName");

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (accessToken, openModalFunc) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~5 days by minimals server
    handleTokenExpired(exp, openModalFunc);
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("_id");
    localStorage.removeItem("teamId");
    localStorage.removeItem("assignedTeamId");
    localStorage.removeItem("teamName");
    localStorage.removeItem("assignedTeamName");
    localStorage.removeItem("teamLogo");
    localStorage.removeItem("permissions");
    localStorage.removeItem("roleName");
    localStorage.removeItem("profileImage");
    localStorage.removeItem("fullName");
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
