import * as Yup from "yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  IconButton,
  InputAdornment,
  Alert,
  Typography,
  Link,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import Iconify from "../../../components/Iconify";
import { PATH_AUTH } from "src/routes/paths";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";

export default function RegisterForm() {
  const { register } = useAuth();

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required("First name required"),
    lastName: Yup.string().required("Last name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    terms: Yup.boolean()
      .oneOf([true], "The terms and conditions must be accepted")
      .required("The terms and conditions must be accepted"),
    teamName: Yup.string().required("Team name required"),
  });

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    teamName: "",
    terms: false,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await register(
        data.email,
        data.phone,
        data.password,
        data.firstName,
        data.lastName,
        data.terms,
        data.teamName
      );
      sessionStorage.setItem("email-verify", data.email);
      navigate(PATH_AUTH.verify);
    } catch (error) {
      console.error(error);
      setError("afterSubmit", { ...error, message: error }); 
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <>
          <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Get started
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                Sign up and create your team.
              </Typography>
            </Box>
          </Box>
        </>

        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        {/* <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper> */}

        <>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <RHFTextField name="firstName" label="First name" />
            <RHFTextField name="lastName" label="Last name" />
          </Stack>

          <RHFTextField name="email" label="Email address" />
          <RHFTextField name="phone" placeholder="111-111-1111" label="Phone" />

          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="body2">
            Password must be at least 6 characters
          </Typography>

          <Typography variant="h5" align="left">
            Setup Team
          </Typography>

          <RHFTextField name="teamName" label="Team Name" />

          <RHFCheckbox
            name="terms"
            label={
              <Typography
                variant="body2"
                align="left"
                sx={{ color: "text.secondary", mt: 1 }}
              >
                By registering, I agree to Keyspace&nbsp;
                <Link underline="always" color="text.primary" href="/auth/terms">
                Terms and Conditions
                </Link>
                {""} and {""}
                <Link underline="always" color="text.primary" href="/auth/privacy-policy">
                    Privacy Policy
                </Link>
                .
              </Typography>
            }
            sx={{ mt: 3 }}
          />
        </>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Register
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
