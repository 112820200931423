import { memo } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function IconArrowElbow({ className, ...props }) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const PRIMARY_DARKER = theme.palette.primary.darker;

  // TODO: Implement theme into SVG

  return (
    <Box {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        fill="currentColor"
        width="100%"
        height="100%"
      >
        <path d="M200 32v144a8 8 0 0 1-8 8H67.31l34.35 34.34a8 8 0 0 1-11.32 11.32l-48-48a8 8 0 0 1 0-11.32l48-48a8 8 0 0 1 11.32 11.32L67.31 168H184V32a8 8 0 0 1 16 0Z" />
      </svg>
    </Box>
  );
}

export default memo(IconArrowElbow);
