import React, { useContext, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import axios from "../utils/axios";
import useIsMountedRef from "../hooks/useIsMountedRef";
import { useLocation } from "react-router-dom";
const ProjectContext = React.createContext();
const ProjectNameContext = React.createContext();
const ProjectMarketplaceIdContext = React.createContext();
const ProjectUpdateContext = React.createContext();
const ProjectUpdateNameContext = React.createContext();
const ProjectUpdateMarketplaceIdContext = React.createContext();

export function useProject() {
  return useContext(ProjectContext);
}

export function useProjectMarketplaceId() {
  return useContext(ProjectMarketplaceIdContext);
}

export function useProjectName() {
  return useContext(ProjectNameContext);
}
export function useProjectUpdate() {
  return useContext(ProjectUpdateContext);
}

export function useProjectMarketplaceIdUpdate() {
  return useContext(ProjectUpdateMarketplaceIdContext);
}

export function useProjectNameUpdate() {
  return useContext(ProjectUpdateNameContext);
}

export const useProjectUpdate2 = () => {
  return useContext(ProjectUpdateContext);
};

export const useProjectNameUpdate2 = () => {
  return useContext(ProjectUpdateNameContext);
};

ProjectProvider.propTypes = {
  children: PropTypes.node,
};
export function ProjectProvider({ children }) {
  const teamId = localStorage.getItem("teamId");
  const isMountedRef = useIsMountedRef();
  const location = useLocation();

  const currentProjectUrl = getProjectId();

  const getProjectName = useCallback(async () => {
    if (
      currentProjectUrl !== "undefined" &&
      currentProjectUrl !== "" &&
      currentProjectUrl !== null
    ) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: localStorage.getItem("accessToken"),
        },
      };
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/teams/` +
            teamId +
            "/projects/" +
            currentProjectUrl,
          config
        );
        if (isMountedRef.current) {
          setCurrentProjectName(response.data.name);
          setCurrentProjectMarketplaceId(response.data.marketplaceId);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setCurrentProjectName(null);
      setCurrentProjectMarketplaceId(null);
    }
  }, [isMountedRef, currentProjectUrl, teamId]);

  function getProjectId() {
    const urlArray = String(location.pathname).split("/");
    let urlProjectId = urlArray[urlArray.indexOf("projects") + 1];
    if (urlProjectId === "new" || !urlProjectId) {
      urlProjectId = null;
    }
    return urlProjectId;
  }

  const [currentProject, setCurrentProject] = useState("");
  const [currentProjectName, setCurrentProjectName] = useState("");
  const [currentProjectMarketplaceId, setCurrentProjectMarketplaceId] =
    useState("");

  useEffect(() => {
    setCurrentProject(currentProjectUrl);
    getProjectName();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function toggleProject(_id) {
    setCurrentProject(_id);
  }
  function toggleNameProject(name) {
    setCurrentProjectName(name);
  }
  function toggleMarketplaceIdProject(id) {
    setCurrentProjectMarketplaceId(id);
  }
  return (
    <ProjectContext.Provider value={currentProject}>
      <ProjectNameContext.Provider value={currentProjectName}>
        <ProjectMarketplaceIdContext.Provider
          value={currentProjectMarketplaceId}
        >
          <ProjectUpdateContext.Provider value={toggleProject}>
            <ProjectUpdateNameContext.Provider value={toggleNameProject}>
              <ProjectUpdateMarketplaceIdContext.Provider
                value={toggleMarketplaceIdProject}
              >
                {children}
              </ProjectUpdateMarketplaceIdContext.Provider>
            </ProjectUpdateNameContext.Provider>
          </ProjectUpdateContext.Provider>
        </ProjectMarketplaceIdContext.Provider>
      </ProjectNameContext.Provider>
    </ProjectContext.Provider>
  );
}
