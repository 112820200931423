import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const PRIMARY = {
  lighter: "#e6e6e7", //keysapce gray
  light: "#D5D5D7", //picked darker then top color
  main: "#231f20", //Keyspace main dark color
  dark: "#161314", //picked darker shade within same color
  darker: "#000000",
};
const SECONDARY = {
  lighter: "#FDEDED",
  light: "#F9C8C8",
  main: "#e41e25", // keyspace red
  dark: "#C9181D",
  darker: "#A41318",
};
const INFO = {
  //blue that is complimentary to keyspace red
  lighter: "#D5E4EC",
  light: "#80ADC6",
  main: "#4C86A8",
  dark: "#3F708D",
  darker: "#2C4F63",
};
const SUCCESS = {
  lighter: "#D3EEDF",
  light: "#98D7B4",
  main: "#04A777",
  dark: "#048B63",
  darker: "#024F38",
};
const WARNING = {
  //yellow complimentary to red
  lighter: "#FFEDC2",
  light: "#FFCE5C",
  main: "#FFBC1F",
  dark: "#F5AB00",
  darker: "#CC8F00",
};
const ERROR = {
  lighter: "#FDEDED",
  light: "#F9C8C8",
  main: "#e41e25", // keyspace red
  dark: "#C9181D",
  darker: "#A41318",
};

const GREY = {
  0: "#FFFFFF",
  100: "#F5F4F4",
  200: "#f5f5f5",
  300: "#DFE3E8",
  400: "#E0E0E0",
  500: "#A1A1A5",
  600: "#858585",
  700: "#5C5C5C",
  800: "#3D3D3D",
  900: "#141414",
  500_8: alpha("#A1A1A5", 0.08),
  500_12: alpha("#A1A1A5", 0.12),
  500_16: alpha("#A1A1A5", 0.16),
  500_24: alpha("#A1A1A5", 0.24),
  500_32: alpha("#A1A1A5", 0.32),
  500_48: alpha("#A1A1A5", 0.48),
  500_56: alpha("#A1A1A5", 0.56),
  500_80: alpha("#A1A1A5", 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
};

const COMMON = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY, contrastText: "#fff" },
  secondary: { ...SECONDARY, contrastText: "#fff" },
  info: { ...INFO, contrastText: "#fff" },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: "#fff" },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: "light",
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
      error: "#e41e25",
    },
    background: { paper: "#fff", default: "#fff", neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: "dark",
    text: {
      primary: "#fff",
      secondary: GREY[500],
      disabled: GREY[600],
      error: "#e41e25",
    },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
