import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/material";

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const logo = (
    <Box sx={{ width: 55, height: 55, ...sx }}>
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 1200"
      >
        <circle fill="#231f20" cx="600" cy="589.81" r="459.82" />
        <path
          fill="#fff"
          d="M692.7,281.37v69.87c96.83,37.34,165.71,131.37,165.71,241.2,0,142.49-115.92,258.41-258.41,258.41s-258.41-115.92-258.41-258.41c0-114.4,74.74-211.65,177.95-245.55v336.63h63.09v-93.29l65.87,92.37,.66,.92h75.01l-79.55-107.12,74.54-87.23h-72.61l-63.92,77.67v-232.22h.02v-66.3c-170.94,9.05-307.23,150.96-307.23,324.11s145.61,324.58,324.58,324.58,324.58-145.6,324.58-324.58c0-146.77-97.93-271.08-231.88-311.07Z"
        />
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
