import * as Yup from "yup";
import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import {
  FormProvider,
  RHFTextField,
  RHFSelect,
} from "../../../components/hook-form";
import axios from "../../../utils/axios";
import requestHeaders from "src/utils/restClient";

export default function InviteUserForm() {
  const isMountedRef = useIsMountedRef();
  const appHeaders = requestHeaders(localStorage.getItem("accessToken"));

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    role: Yup.string().required("Please select a role"),
  });

  const teamId = localStorage.getItem("teamId");
  const teamName = localStorage.getItem("teamName");
  let teamLogo = localStorage.getItem("teamLogo");

  if (
    teamLogo === undefined ||
    teamLogo === "undefined" ||
    (teamLogo === "") | (teamLogo === null)
  ) {
    teamLogo = "";
  }

  const defaultValues = {
    email: "",
    role: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const [formSubmitted, setFormSubmitted] = useState(false);

  //Display the success alert for 3 seconds after submit
  useEffect(() => {
    if (formSubmitted === true) {
      new Promise((resolve) => setTimeout(resolve, 3000));
      return () => {
        setFormSubmitted(false); 
      };
      
    }
  }, [formSubmitted]);

  const onSubmit = async (data) => {
    const postData = {
      email: data.email,
      role: data.role,
      teamId: teamId,
      team: {
        teamId: teamId,
        teamName: teamName,
        teamLogo: teamLogo,
      },
    };
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/teams/${teamId}/invites`,
        postData,
        appHeaders
      );
      reset();
      setFormSubmitted(true);
    } catch (error) {
      setFormSubmitted(false);
      reset();
      if (isMountedRef.current) {
        setError("afterSubmit", { ...error, message: error });
      }
    }
  };

  //Populate the allowed list of user roles
  const roles = GetMyRoles(appHeaders);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ mt: 2 }}>
        {!!errors.afterSubmit && (
          <Alert severity="error">Could not issue invite.<br/><b>Error:</b><br/>{errors.afterSubmit.message}</Alert>
        )}
        {formSubmitted && (
          <Alert severity="success">Invite has been sent.</Alert>
        )}

        <RHFSelect name="role" label="User role">
          <option key="" value=""></option>
          {roles.map((option) => (
            <option key={option._id} value={option._id}>
              {option.name}
            </option>
          ))}
        </RHFSelect>

        <RHFTextField name="email" label="Email address" />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Invite User
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

function GetMyRoles(config) {
  const [availRoles, setAvailRole] = useState([]);
  const isMountedRef = useIsMountedRef();
  const getRoles = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getRolesToAssign`,
        config
      );
      if (isMountedRef.current) {
        setAvailRole(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef, config]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  return availRoles;
}
