import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Stack,
  Drawer,
  Typography,
  Link,
} from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
import cssStyles from "../../../utils/cssStyles";
import { NAVBAR } from "../../../config";
import Logo from "../../../components/Logo";
import Scrollbar from "../../../components/Scrollbar";
import { NavSectionVertical } from "../../../components/nav-section";
import CurrentProject from "./NavConfig";
import NavbarAccount from "./NavbarAccount";
import CollapseButton from "./CollapseButton";
import Iconify from "src/components/Iconify";
import { Link as RouterLink } from "react-router-dom";

import { PATH_DASHBOARD } from "src/routes/paths";
import NavbarUsers from "./NavbarUsers";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));
const RootStyle2 = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 3.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.error.lighter,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  const version = "1.5.3";

  const { pathname } = useLocation();
  const loggedUserPermission = localStorage.getItem("permissions");
  const teamId = localStorage.getItem("teamId");
  const assignedTeamId = localStorage.getItem("assignedTeamId");
  const isDesktop = useResponsive("up", "lg");

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: 1,
          "& .simplebar-content": {
            height: 1,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Stack
          spacing={3}
          sx={{
            pt: 3,
            pb: 2,
            px: 2.5,
            flexShrink: 0,
            ...(isCollapse && { alignItems: "center" }),
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Logo />
            
            <Typography variant="body2" color="textSecondary">
              {`v${version}`}
            </Typography>

            {isDesktop && !isCollapse && (
              <CollapseButton
                onToggleCollapse={onToggleCollapse}
                collapseClick={collapseClick}
              />
            )}
          </Stack>

          <NavbarAccount isCollapse={isCollapse} />

          {loggedUserPermission.includes("admin:manageTeams") &&
            teamId !== assignedTeamId && (
              <NavbarUsers isCollapse={isCollapse} />
            )}
        </Stack>

        <NavSectionVertical
          navConfig={CurrentProject}
          isCollapse={isCollapse}
        />

        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>

      {loggedUserPermission.includes("admin:manageTeams") && (
        <Stack
          spacing={3}
          sx={{
            pt: 3,
            pb: 2,
            px: 2.5,
            flexShrink: 0,
            ...(isCollapse && { alignItems: "center" }),
          }}
        >
          <Box sx={{ height: 50 }} />
          <Stack
            direction="row"
            alignItems="center"
            sx={{ position: "fixed", bottom: 20 }}
          >
            <Link
              underline="none"
              color="inherit"
              component={RouterLink}
              to={PATH_DASHBOARD.teams.root}
            >
              <RootStyle2
                sx={{
                  ...(isCollapse && {
                    bgcolor: "transparent",
                  }),
                }}
              >
                <Iconify
                  icon={"eos-icons:admin-outlined"}
                  width={26}
                  height={26}
                  sx={{ mr: 1.5 }}
                />
                {!isCollapse && (
                  <Box>
                    <Typography variant="subtitle1">
                      <strong>Teams Admin Zone</strong>
                    </Typography>
                    <Typography
                      variant="body2"
                      noWrap
                      sx={{ color: "text.secondary" }}
                    >
                      All Teams
                    </Typography>
                  </Box>
                )}
              </RootStyle2>
            </Link>
          </Stack>
        </Stack>
      )}
    </>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: "dashed",
              bgcolor: "background.default",
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
