import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { io } from "socket.io-client";
import useAuth from "../hooks/useAuth";

const ChatContext = createContext({});

ChatContextProvider.propTypes = {
  children: PropTypes.node,
};

function ChatContextProvider({ children }) {
  const { user } = useAuth();

  const [messages, setMessages] = useState([]);

  const socket = useRef(
    io(
      process.env.REACT_APP_CHAT_WEBSOCKET ||
        "wss://kai.dennislysenko.com/chat",
      {
        autoConnect: false,
        extraHeaders: {
          "X-User-ID": user._id,
        },
      }
    )
  );

  useEffect(() => {
    socket.current.on("disconnect", () => {
      socket.current.removeAllListeners("stream");
      socket.current.removeAllListeners("context");
    });
  }, []);

  const sendContext = useCallback((context) => {
    if (!socket.current.connected) {
      const func = () => {
        socket.current.emit("context", { context });
        socket.current.removeListener("connect", func);
      };
      socket.current.on("connect", func);
    }

    socket.current.emit("context", { context });
  }, []);

  const clearHistory = useCallback(async () => {
    if (!socket.current.connected) return;

    const newHistory = await socket.current.emitWithAck("clear");
    console.log(newHistory);
    setMessages(newHistory);
  }, []);

  return (
    <ChatContext.Provider
      value={{ sendContext, socket, clearHistory, messages, setMessages }}
    >
      {children}
    </ChatContext.Provider>
  );
}

const useChatContext = () => useContext(ChatContext);

export { ChatContextProvider, useChatContext };
