import useAuth from "../hooks/useAuth";
import createAvatar from "../utils/createAvatar";
import Avatar from "./Avatar";
import axios from "../utils/axios";
import useIsMountedRef from "../hooks/useIsMountedRef";
import { useEffect, useCallback, useState } from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";

export default function MyAvatar({ ...other }) {
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#fff",
      color: "#e41e25",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        //animation: 'ripple 1.2s infinite ease-in-out',
        border: "0px solid currentColor",
        content: '"A"',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const profileImage = localStorage.getItem("profileImage");
  const fullName = localStorage.getItem("fullName");
  const teamId = localStorage.getItem("teamId");

  const { user } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [downloadedProfileImage, setDownloadedProfileImage] = useState(null);

  const getProfileImage = useCallback(async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("accessToken"),
      },
    };
    const postData = {
      bucketKey: profileImage,
    };
    if (
      profileImage !== "undefined" &&
      profileImage !== undefined &&
      profileImage !== "" &&
      profileImage !== null
    ) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/teams/` +
            teamId +
            "/users/" +
            user._id +
            "/downloadUserFile",
          postData,
          config
        );
        if (isMountedRef.current) {
          setDownloadedProfileImage(response.data.url);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [isMountedRef, profileImage, teamId, user._id]);

  useEffect(() => {
    getProfileImage();
  }, [profileImage, getProfileImage]);

  return (
    <>
      {user.role.name === "Team Admin" ||
      user.role === "62a96b4b05b561725883552c" ? (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <Avatar
            src={downloadedProfileImage}
            alt={fullName}
            color={
              downloadedProfileImage ? "default" : createAvatar(fullName).color
            }
            {...other}
          >
            {createAvatar(fullName).name}
          </Avatar>
        </StyledBadge>
      ) : (
        <Avatar
          src={downloadedProfileImage}
          alt={fullName}
          color={
            downloadedProfileImage ? "default" : createAvatar(fullName).color
          }
          {...other}
        >
          {createAvatar(fullName).name}
        </Avatar>
      )}
    </>
  );
}
