import { lazy, Suspense } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import { PATH_AFTER_LOGIN } from "../config";
import LoadingScreen from "../components/LoadingScreen";
import MainLayout from "src/layouts/main";
import MainLayoutPreview from "src/layouts/main/preview/indexPreview";
import { ChatContextProvider } from "../contexts/ChatContext";
import { ProjectIdContext } from "../components/chat-bot/training-data/ProjectIdContext";

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "privacy-policy",
          element: (
            <GuestGuard>
              <PrivacyPolicy />
            </GuestGuard>
          ),
        },
        {
          path: "terms",
          element: (
            <GuestGuard>
              <Terms />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "new-password", element: <NewPassword /> },
        { path: "verify", element: <VerifyCode /> },
        {
          path: "register-invite",
          element: (
            <GuestGuard>
              <RegisterViaInvite />
            </GuestGuard>
          ),
        },
      ],
    },

    {
      path: "marketplaces",
      element: <MainLayout />,
      children: [
        {
          path: ":mid/collections",
          element: <MarketplaceAllCollectionsView />,
        },
        {
          path: ":mid/collections/:cid",
          element: <MarketplaceCollectionView />,
        },
        {
          path: ":mid/collections/:cid/products/:pid",
          element: <MarketplaceProductView />,
        },
        {
          path: ":mid/contracts/:cid/tokens/:tid",
          element: <MarketplaceProfileNftView />,
        },
        {
          path: ":mid",
          element: <MarketplaceView />,
        },
        {
          path: ":mid/profile",
          element: <MarketplaceProfile />,
        },
        {
          path: ":mid/offers",
          element: <MarketplaceAllOffersView />,
        },
        {
          path: ":mid/offers/:oid/view",
          element: <OfferViewByIdPublic />,
        },
      ],
    },

    // Dashboard Routes
    {
      path: "",
      element: (
        <AuthGuard>
          <ChatContextProvider>
            <DashboardLayout />
          </ChatContextProvider>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "projects", element: <ProjectsView /> },
        {
          path: "user",
          children: [{ path: "account", element: <UserAccount /> }],
        },
        {
          path: "teams",
          element: <AdminTeamMngmtInfoContext />,
          children: [
            { path: "", element: <TeamsView /> },
            { path: ":tid/approve", element: <TeamApproveById /> },
            { path: ":tid/manage", element: <TeamManageById /> },
          ],
        },
        {
          path: "projects",
          element: <ProjectIdContext />,
          children: [
            { path: "new", element: <NewProject /> },
            { path: ":pid/view", element: <ProjectViewById /> },
            { path: ":pid/edit", element: <ProjectEditById /> },
            {
              path: ":pid/analytics/collections",
              element: <ProjectCollectionsAnalytics />,
            },
            {
              path: ":pid/analytics/collections/:cid",
              element: <ProjectCollectionsAnalyticsById />,
            },
            {
              path: ":pid/analytics/collections/:cid/products/:tid",
              element: <ProjectCollectionProductAnalyticsById />,
            },
            {
              path: ":pid/analytics/offers",
              element: <ProjectOffersAnalytics />,
            },
            {
              path: ":pid/analytics/offers/:oid",
              element: <ProjectOffersAnalyticsById />,
            },
            {
              path: ":pid/analytics/marketplaces",
              element: <ProjectMarketplacesAnalytics />,
            },
            {
              path: ":pid/analytics/users",
              element: <ProjectUsersAnalytics />,
            },
            // analytics on specific user
            { //🔴 needs rework
              element: <CollectionInfoContext />,
              children: [
                { path: ":pid/collections", element: <CollectionsView /> },
                { path: ":pid/collections/new", element: <NewCollection /> },
                {
                  path: ":pid/collections/:cid/view",
                  element: <CollectionViewById />,
                },
                {
                  path: ":pid/collections/:cid/edit",
                  element: <CollectionEditById />,
                },
                {
                  path: ":pid/collections/:cid/approve",
                  element: <CollectionApproveById />,
                },
                {
                  path: ":pid/collections/:cid/mint",
                  element: <CollectionMintById />,
                },
              ],
            },
            {
              element: <OfferInfoContext />,
              children: [
                { path: ":pid/offers", element: <OffersView /> },
                { path: ":pid/offers/new", element: <NewOffer /> },
                { path: ":pid/offers/:oid/view", element: <OfferViewById /> },
                {
                  path: ":pid/offers/:oid/preview",
                  element: <OfferViewByIdPreview />,
                },
                { path: ":pid/offers/:oid/edit", element: <OfferEditById /> },
              ],
            },
            {
              element: <MarketplaceInfoContext />,
              children: [
                {
                  path: ":pid/marketplaces/configure",
                  element: <MarketplaceConfigure />,
                },
                {
                  path: ":pid/marketplaces/deploy",
                  element: <MarketplaceDeployAndListCollections />,
                },
                {
                  path: ":pid/marketplaces/marketing",
                  element: <MarketplaceMarketing />,
                  // children: [
                  //   { path: "qrcodes/:qr_cp", element: <QRCodeCampaignByID /> },
                  // ]
                },
                {
                  path: ":pid/marketplaces/marketing/qrcodes/:qr_cp",
                  element: <QRCodeCampaignByID />,
                },
                {
                  path: ":pid/marketplaces/manage",
                  element: <MarketplaceManage />,
                },
                {
                  path: ":pid/marketplaces/preview",
                  element: <MainLayoutPreview />,
                  children: [
                    { path: "layout", element: <MarketplacePreview /> },
                  ],
                },
              ],
            },
          ],
        },

        { path: "permission-denied", element: <PermissionDenied /> },

        { path: "revenue-report", element: <RevenueReportView /> },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "maintenance", element: <Maintenance /> },
        { path: "pricing", element: <Pricing /> },
        { path: "payment", element: <Payment /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
        {
          path: "teams/:id/projects/:pid/collections/:cid/files/:fid",
          element: (
            <AuthGuard>
              <FileViewById />
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/",
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const PrivacyPolicy = Loadable(
  lazy(() => import("../pages/legal/PrivacyPolicy"))
);
const Terms = Loadable(lazy(() => import("../pages/legal/Terms")));
const RegisterViaInvite = Loadable(
  lazy(() => import("../pages/auth/RegisterViaInvite"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const NewPassword = Loadable(lazy(() => import("../pages/auth/NewPassword")));
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));

// DASHBOARD

//TEAMS
const TeamsView = Loadable(lazy(() => import("../pages/teams/TeamsView")));
const TeamApproveById = Loadable(
  lazy(() => import("../pages/teams/TeamApproveById"))
);
const TeamManageById = Loadable(
  lazy(() => import("../pages/teams/TeamManageById"))
);

// PROJECTS
const ProjectsView = Loadable(
  lazy(() => import("../pages/projects/ProjectsView"))
);
const ProjectViewById = Loadable(
  lazy(() => import("../pages/projects/ProjectViewById"))
);
const ProjectEditById = Loadable(
  lazy(() => import("../pages/projects/ProjectEditById"))
);
const NewProject = Loadable(lazy(() => import("../pages/projects/NewProject")));

//ANALYTICS

const ProjectCollectionsAnalytics = Loadable(
  lazy(() => import("../pages/analytics/CollectionAnalytics"))
);

const ProjectCollectionProductAnalyticsById = Loadable(
  lazy(() => import("../pages/analytics/ProductAnalyticsById"))
);

const ProjectCollectionsAnalyticsById = Loadable(
  lazy(() => import("../pages/analytics/CollectionAnalyticsById"))
);

const ProjectOffersAnalytics = Loadable(
  lazy(() => import("../pages/analytics/OfferAnalytics"))
);

const ProjectOffersAnalyticsById = Loadable(
  lazy(() => import("../pages/analytics/OfferAnalyticsById"))
);

const ProjectMarketplacesAnalytics = Loadable(
  lazy(() => import("../pages/analytics/MarketplaceAnalytics"))
);

const ProjectUsersAnalytics = Loadable(
  lazy(() => import("../pages/analytics/UserAnalytics"))
);

// COLLECTIONS
const CollectionsView = Loadable(
  lazy(() => import("../pages/collections/CollectionsView"))
);
const NewCollection = Loadable(
  lazy(() => import("../pages/collections/NewCollection"))
);
const CollectionViewById = Loadable(
  lazy(() => import("../pages/collections/CollectionViewById"))
);
const CollectionEditById = Loadable(
  lazy(() => import("../pages/collections/CollectionEditById"))
);
const CollectionApproveById = Loadable(
  lazy(() => import("../pages/collections/CollectionApproveById"))
);
const CollectionMintById = Loadable(
  lazy(() => import("../pages/collections/CollectionMintById"))
);

// OFFERS
const OffersView = Loadable(lazy(() => import("../pages/offers/OffersView")));
const NewOffer = Loadable(lazy(() => import("../pages/offers/NewOffer")));
const OfferViewById = Loadable(
  lazy(() => import("../pages/offers/OfferViewById"))
);
const OfferEditById = Loadable(
  lazy(() => import("../pages/offers/OfferEditById"))
);

const OfferViewByIdPublic = Loadable(
  lazy(() => import("../pages/offers/OfferViewByIdPublic"))
);
const OfferViewByIdPreview = Loadable(
  lazy(() => import("../pages/offers/OfferViewByIdPreview"))
);

// REVENUE REPORT
const RevenueReportView = Loadable(
  lazy(() => import("../pages/revenue-report/RevenueReportView"))
);

//MARKETPLACES

const MarketplaceConfigure = Loadable(
  lazy(() => import("../pages/marketplace/MarketplaceConfigure"))
);

const MarketplaceDeployAndListCollections = Loadable(
  lazy(() => import("../pages/marketplace/MarketplaceDeployAndListCollections"))
);
const MarketplaceMarketing = Loadable(
  lazy(() => import("../pages/marketplace/MarketplaceMarketing"))
);
const QRCodeCampaignByID = Loadable(
  lazy(() => import("../pages/marketplace/marketing/QRCodeCampaignByID"))
);
const MarketplaceManage = Loadable(
  lazy(() => import("../pages/marketplace/MarketplaceManage"))
);

const MarketplacePreview = Loadable(
  lazy(() => import("../pages/marketplace/MarketplacePreview"))
);

const MarketplaceCollectionView = Loadable(
  lazy(() => import("../pages/marketplace/MarketplaceCollectionView"))
);

const MarketplaceAllCollectionsView = Loadable(
  lazy(() => import("../pages/marketplace/MarketplaceAllCollectionsView"))
);

const MarketplaceAllOffersView = Loadable(
  lazy(() => import("../pages/marketplace/MarketplaceAllOffersView"))
);

const MarketplaceProductView = Loadable(
  lazy(() => import("../pages/marketplace/MarketplaceProductView"))
);

const MarketplaceProfileNftView = Loadable(
  lazy(() => import("../pages/marketplace/MarketplaceProfileNftView"))
);

const MarketplaceView = Loadable(
  lazy(() => import("../pages/marketplace/MarketplaceView"))
);

const MarketplaceProfile = Loadable(
  lazy(() => import("../pages/marketplace/MarketplaceProfile"))
);

// USER
const UserAccount = Loadable(lazy(() => import("../pages/UserAccount")));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(
  lazy(() => import("../pages/PermissionDenied"))
);

// MAIN
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Pricing = Loadable(lazy(() => import("../pages/Pricing")));
const Payment = Loadable(lazy(() => import("../pages/Payment")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const Page403 = Loadable(lazy(() => import("../pages/Page403")));
const Page404 = Loadable(lazy(() => import("../pages/Page404")));
const FileViewById = Loadable(lazy(() => import("../pages/FileViewById")));

// CHAT BOT CONTEXT
const MarketplaceInfoContext = Loadable(
  lazy(() => import("../components/chat-bot/training-data/marketplaceInfo"))
);
const AdminTeamMngmtInfoContext = Loadable(
  lazy(() => import("../components/chat-bot/training-data/adminTeamMngmtInfo"))
);
const CollectionInfoContext = Loadable(
  lazy(() => import("../components/chat-bot/training-data/collectionInfo"))
);
const OfferInfoContext = Loadable(
  lazy(() => import("../components/chat-bot/training-data/offerInfo"))
);
