import { Outlet } from "react-router-dom";
import { Box, Container, Typography, Stack } from "@mui/material";
import MainHeaderPreview from "./MainHeaderPreview";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { SkeletonPostItem } from "src/components/skeleton";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import Image from "src/components/Image";
import { MotionContainer, varBounce } from "../../../components/animate";
import { PageNotFoundIllustration } from "../../../assets";
import { m } from "framer-motion";
import { styled } from "@mui/material/styles";
import { useProject } from "src/contexts/ProjectContext";
import requestHeaders from "src/utils/restClient";

export const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function MainLayoutPreview() {
  const appHeaders = requestHeaders(localStorage.getItem("accessToken"));
  const [marketplace, setMarketplace] = useState(null);
  const [logoHeader, setLogoHeader] = useState();
  const isMountedRef = useIsMountedRef();
  const [downloadedLogoImage, setDownloadedLogoImage] = useState(null);
  const [mainColor, setMainColor] = useState("#231f20");
  const [accentColor, setAccentColor] = useState("#231f20");

  const [error, setError] = useState(false);

  const teamId = localStorage.getItem("teamId");
  const projectId = useProject();
  const [marketplaceId, setMarketplaceId] = useState(null);

  //GET marketplace by ID - private endpoint
  const getMarketplaceById = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/teams/${teamId}/projects/${projectId}/getMarketplaceById`,
        appHeaders
      );
      setMarketplace(response.data.marketplaceId);
      setMarketplaceId(response.data.marketplaceId._id);
    } catch (error) {
      console.error(error);
      setError(true);
    }
  }, [appHeaders, projectId, teamId]);

  useEffect(() => {
    getMarketplaceById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (marketplace !== null && marketplace !== undefined) {
      setLogoHeader(
        marketplace.components.filter(function (el) {
          return el.component === "Logo & Header Module";
        })
      );
      const settings = marketplace.components.filter(function (el) {
        return el.uuid === "settings";
      });
      if (settings[0] !== undefined) {
        const colorMain = settings[0].attributes.filter(function (el) {
          return el.key === "colorMain";
        });
        if (
          colorMain.length > 0 &&
          colorMain[0].value !== null &&
          colorMain[0].value !== "" &&
          colorMain[0].value !== undefined
        ) {
          setMainColor(colorMain[0].value);
        }
        const colorAccent = settings[0].attributes.filter(function (el) {
          return el.key === "colorAccent";
        });
        if (
          colorAccent.length > 0 &&
          colorAccent[0].value !== null &&
          colorAccent[0].value !== "" &&
          colorAccent[0].value !== undefined
        ) {
          setAccentColor(colorAccent[0].value);
        }
      }
    }
  }, [marketplace]);

  const getLogoImage = async () => {
    if (logoHeader !== undefined) {
      if (logoHeader.length > 0) {
        const imageBkKeyValue = logoHeader[0].attributes.filter(function (el) {
          return el.key === "imageBk";
        });

        const imageBk = imageBkKeyValue[0].value;

        const postData = {
          bucketKey: imageBk,
        };

        if (
          imageBk !== "undefined" &&
          imageBk !== undefined &&
          imageBk !== "" &&
          imageBk !== null
        ) {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/downloadFile`,
              postData
            );

            if (isMountedRef.current) {
              setDownloadedLogoImage(response.data.url);
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
  };

  useEffect(() => {
    getLogoImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoHeader]);

  return (
    <Stack sx={{ minHeight: 1 }}>
      {!error && (
        <>
          {marketplace !== undefined ? (
            <>
              {downloadedLogoImage !== undefined &&
                downloadedLogoImage !== null && (
                  <MainHeaderPreview
                    marketplaceId={marketplaceId}
                    component={logoHeader}
                    mainColor={mainColor}
                    accentColor={accentColor}
                    fileUrl={downloadedLogoImage}
                  />
                )}
              <Outlet
                context={[marketplace, marketplaceId, mainColor, accentColor]}
              />
              <Box
                sx={{
                  py: 5,
                  textAlign: "center",
                  position: "relative",
                  bgcolor: "background.default",
                }}
              >
                <Container>
                  {downloadedLogoImage !== null &&
                    downloadedLogoImage !== undefined && (
                      <Image
                        alt="marketplace logo"
                        src={downloadedLogoImage}
                        sx={{ mb: 1, mx: "auto", width: 55, height: 55 }}
                      />
                    )}
                  <Typography variant="caption" component="p">
                    © All rights reserved
                  </Typography>
                </Container>
              </Box>
            </>
          ) : (
            <SkeletonPostItem></SkeletonPostItem>
          )}
        </>
      )}
      {error && (
        <Container component={MotionContainer}>
          <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                Oops, marketplace not found!
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography sx={{ color: "text.secondary" }}>
                Sorry, we couldn't find the marketplace you're looking for.
                Perhaps you've mistyped the URL? Be sure to check your spelling.
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <PageNotFoundIllustration
                sx={{ height: 260, my: { xs: 5, sm: 10 } }}
              />
            </m.div>
          </ContentStyle>
        </Container>
      )}
    </Stack>
  );
}
