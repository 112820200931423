import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Stack, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { PATH_AUTH } from "../../../routes/paths";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "../../../utils/axios";
import useIsMountedRef from "../../../hooks/useIsMountedRef";

export default function ResetPasswordForm() {
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: { email: "" },
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      //Trigger email to reset password
      await new Promise((resolve) => setTimeout(resolve, 500));
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/forgotPassword`, {
        email: data.email,
      });
      sessionStorage.setItem("email-recovery", data.email);
      navigate(PATH_AUTH.newPassword);
    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError("afterSubmit", { ...error, message: error });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">Email not found. Please try again.</Alert>
        )}
        <RHFTextField name="email" label="Email address" />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Send Request
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
