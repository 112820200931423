import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
import { PATH_DASHBOARD } from "../../../routes/paths";
import TeamAvatar from "../../../components/TeamAvatar";
import {
  useProjectUpdate,
  useProjectNameUpdate,
  useProjectMarketplaceIdUpdate,
} from "../../../contexts/ProjectContext";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const teamName = localStorage.getItem("teamName");
  const toggleProject = useProjectUpdate();
  const toggleProjectName = useProjectNameUpdate();
  const toggleProjectMarketplace = useProjectMarketplaceIdUpdate();

  function handleProjectChange() {
    toggleProject(null);
    toggleProjectName(null);
    toggleProjectMarketplace(null);
  }

  return (
    <Link
      onClick={() => handleProjectChange()}
      underline="none"
      color="inherit"
      component={RouterLink}
      to={PATH_DASHBOARD.projects.root}
    >
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: "transparent",
          }),
        }}
      >
        <TeamAvatar />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle1" noWrap>
            Team Projects
          </Typography>
          {teamName !== null && (
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {teamName}
            </Typography>
          )}
        </Box>
      </RootStyle>
    </Link>
  );
}
