import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useChatContext } from "../../../contexts/ChatContext";

export function ProjectIdContext() {
  const { sendContext } = useChatContext();
  const { pid } = useParams();

  useEffect(() => {
    sendContext({ project_id: pid });

    return () => {
      sendContext({
        project_id: null,
      });
    };
  }, [pid]);

  return <Outlet />;
}
