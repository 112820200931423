function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "";
const ROOTS_PAGE = "";

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  registerInvite: path(ROOTS_AUTH, "/register-invite"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_PAGE = {
  root: ROOTS_PAGE,
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  filepage: (id, pid, cid, fid) =>
    path(
      ROOTS_PAGE,
      `/teams/${id}/projects/${pid}/collections/${cid}/files/${fid}`
    ),
  marketplaceView: (mid) => path(ROOTS_PAGE, `/marketplaces/${mid}`),
  marketplaceProfile: (mid) => path(ROOTS_PAGE, `/marketplaces/${mid}/profile`),
  marketplaceCollection: (mid, cid) =>
    path(ROOTS_PAGE, `/marketplaces/${mid}/collections/${cid}`),
  allMarketplaceCollections: (mid) =>
    path(ROOTS_PAGE, `/marketplaces/${mid}/collections`),
  allMarketplaceOffers: (mid) =>
    path(ROOTS_PAGE, `/marketplaces/${mid}/offers`),
  marketplaceProduct: (mid, cid, pid) =>
    path(ROOTS_PAGE, `/marketplaces/${mid}/collections/${cid}/products/${pid}`),
  marketplaceProfileNft: (mid, cid, tid) =>
    path(ROOTS_PAGE, `/marketplaces/${mid}/contracts/${cid}/tokens/${tid}`),
  viewOffer: (mid, oid) =>
    path(ROOTS_DASHBOARD, `/marketplaces/${mid}/offers/${oid}/view`),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    projects: path(ROOTS_DASHBOARD, "/projects"),
    teams: path(ROOTS_DASHBOARD, "/teams"),
  },
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
  teams: {
    root: path(ROOTS_DASHBOARD, "/teams"),
    manageTeam: (tid) => path(ROOTS_DASHBOARD, `/teams/${tid}/manage`),
    approveTeam: (tid) => path(ROOTS_DASHBOARD, `/teams/${tid}/approve`),
  },
  projects: {
    root: path(ROOTS_DASHBOARD, "/projects"),
    new: path(ROOTS_DASHBOARD, "/projects/new"),
    view: (pid) => path(ROOTS_DASHBOARD, `/projects/${pid}/view`),
    edit: (pid) => path(ROOTS_DASHBOARD, `/projects/${pid}/edit`),
    analytics: (pid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/analytics/overview`),
    // analyticsCollections: (pid) =>
    //   path(ROOTS_DASHBOARD, `/projects/${pid}/analytics/collections`),
    // analyticsCollectionById: (pid, collectionId) =>
    //   path(
    //     ROOTS_DASHBOARD,
    //     `/projects/${pid}/analytics/collections/${collectionId}`
    //   ),
    // analyticsCollectionProductById: (pid, collectionId, tokenId) =>
    //   path(
    //     ROOTS_DASHBOARD,
    //     `/projects/${pid}/analytics/collections/${collectionId}/products/${tokenId}`
    //   ),
    // analyticsOffers: (pid) =>
    //   path(ROOTS_DASHBOARD, `/projects/${pid}/analytics/offers`),
    // analyticsOfferById: (pid, offerId) =>
    //   path(ROOTS_DASHBOARD, `/projects/${pid}/analytics/offers/${offerId}`),
    analyticsMarketplaces: (pid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/analytics/marketplaces`),
    analyticsUsers: (pid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/analytics/users`),
    offers: (pid) => path(ROOTS_DASHBOARD, `/projects/${pid}/offers`),
    newOffer: (pid) => path(ROOTS_DASHBOARD, `/projects/${pid}/offers/new`),
    viewOffer: (pid, oid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/offers/${oid}/view`),
    previewOffer: (pid, oid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/offers/${oid}/preview`),
    editOffer: (pid, oid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/offers/${oid}/edit`),
    collections: (pid) => path(ROOTS_DASHBOARD, `/projects/${pid}/collections`),
    newCollection: (pid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/collections/new`),
    viewCollection: (pid, cid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/collections/${cid}/view`),
    editCollection: (pid, cid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/collections/${cid}/edit`),
    approveCollection: (pid, cid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/collections/${cid}/approve`),
    mintCollection: (pid, cid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/collections/${cid}/mint`),
    marketplaceConfigure: (pid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/marketplaces/configure`),
    marketplaceDeploy: (pid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/marketplaces/deploy`),
    marketplaceMarketing: (pid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/marketplaces/marketing`),
    qrCodeCampaignByID: (pid , qr_cp) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/marketplaces/marketing/qrcodes/${qr_cp}`),
    marketplaceManage: (pid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/marketplaces/manage`),
    marketplacePreview: (pid) =>
      path(ROOTS_DASHBOARD, `/projects/${pid}/marketplaces/preview/layout`),
  },
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
