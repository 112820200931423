import React from "react";
import ThemeProvider from "./theme";
import Router from "./routes";
import { SnackbarProvider } from "notistack";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";

export const ProjectContext = React.createContext();
export default function App() {
  return (
    <SnackbarProvider>
      <MotionLazyContainer>
        <ThemeProvider>
          <Router />
        </ThemeProvider>
      </MotionLazyContainer>
    </SnackbarProvider>
  );
}
