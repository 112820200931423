import { PATH_DASHBOARD } from "../../../routes/paths";
import SvgIconStyle from "../../../components/SvgIconStyle";
import {
  useProject,
  useProjectName,
  useProjectMarketplaceId,
} from "../../../contexts/ProjectContext";

const getIcon = (name) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const CurrentProject = () => {
  const selectedProject = useProject();
  const selectedProjectName = useProjectName();
  const selectedProjectMarketplaceId = useProjectMarketplaceId();
  let navConfig = [];
  if (selectedProject != null) {
    navConfig = [
      {
        subheader: selectedProjectName,
        items: [
          {
            title: "Project Details",
            path: PATH_DASHBOARD.projects.view(selectedProject),
            icon: ICONS.projectItem,
          },
          {
            title: "Collections",
            path: PATH_DASHBOARD.projects.collections(selectedProject),
            icon: ICONS.collection,
          },
          {
            title: "Special Offers",
            path: PATH_DASHBOARD.projects.offers(selectedProject),
            icon: ICONS.offer,
          },
          {
            title: "Marketplace",
            icon: ICONS.marketplace,
            children: [
              {
                title: "Deploy",
                path: PATH_DASHBOARD.projects.marketplaceDeploy(
                  selectedProject
                ),
              },
              {
                title: "Configure",
                path: PATH_DASHBOARD.projects.marketplaceConfigure(
                  selectedProject
                ),
              },
              // {
              //   title: "Marketing",
              //   path: PATH_DASHBOARD.projects.marketplaceMarketing(
              //     selectedProject
              //   ),
              // },
              {
                title: "Marketing",
                path: PATH_DASHBOARD.projects.marketplaceMarketing(
                  selectedProject
                ),
              },
              {
                title: "Manage",
                path: PATH_DASHBOARD.projects.marketplaceManage(
                  selectedProject
                ),
              },
              {
                title: "Visit",
                //path: PATH_PAGE.marketplaceView(selectedProjectMarketplaceId),
                path: process.env.REACT_APP_BASE_URL + "/marketplaces/" + selectedProjectMarketplaceId
              },
            ],
          },
          {
            title: "Analytics",
            icon: ICONS.analytics,
            children: [
              {
                title: "Marketplaces",
                path: PATH_DASHBOARD.projects.analyticsMarketplaces(
                  selectedProject
                ),
              },
              {
                title: "Users",
                path: PATH_DASHBOARD.projects.analyticsUsers(
                  selectedProject
                ),
              },
              // {
              //   title: "Collections",
              //   path: PATH_DASHBOARD.projects.analyticsCollections(
              //     selectedProject
              //   ),
              // },
              // {
              //   title: "Offers",
              //   path: PATH_DASHBOARD.projects.analyticsOffers(selectedProject),
              // },
            ],
          },
        ],
      },
    ];
  }
  return navConfig;
};

const ICONS = {
  menuItem: getIcon("ic_menu_item"),
  projectItem: getIcon("project-outlined"),
  collection: getIcon("collection"),
  offer: getIcon("special-pages-rtl"),
  marketplace: getIcon("cart-supermarket"),
  analytics: getIcon("ic_analytics"),
};

export default CurrentProject;
