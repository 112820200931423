import PropTypes from "prop-types";
import { createContext, useEffect, useReducer, useState } from "react";
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import { SessionDialog } from "src/utils/sessionDialog";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state) => {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  },
  REGISTERVIAINVITE: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  registerViaInvite: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, setOpen);

          const teamId = localStorage.getItem("teamId");
          const userId = localStorage.getItem("_id");

          const config = {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: localStorage.getItem("accessToken"),
            },
          };

          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/teams/${teamId}/users/${userId}`,
            config
          );
          const user = response.data;

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/login`,
      {
        email,
        password,
      }
    );

    console.log("response.data", response.data)

    const accessToken = response.data.token;
    const user = response.data;

    setSession(accessToken, setOpen);
    localStorage.setItem("_id", user["_id"]);
    localStorage.setItem("teamId", user.team.teamId);
    localStorage.setItem("assignedTeamId", user.team.teamId);
    localStorage.setItem("teamName", user.team.teamName);
    localStorage.setItem("assignedTeamName", user.team.teamName);
    localStorage.setItem("teamLogo", user.team.teamLogo);
    localStorage.setItem("assignedTeamLogo", user.team.teamLogo);
    localStorage.setItem("permissions", user.role.permissions);
    localStorage.setItem("roleName", user.role.name);
    localStorage.setItem("profileImage", user.profileImage);
    localStorage.setItem("fullName", user.fullName);

    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };

  const register = async (
    email,
    phone,
    password,
    firstName,
    lastName,
    terms,
    teamName
  ) => {
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register`, {
      email,
      password,
      firstName,
      lastName,
      phone,
      terms,
      teamName,
    });

    dispatch({
      type: "REGISTER",
    });
  };

  const registerViaInvite = async (
    email,
    phone,
    password,
    firstName,
    lastName,
    terms,
    key
  ) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/registerviainvite`,
      {
        email,
        phone,
        password,
        firstName,
        lastName,
        terms,
        key,
      }
    );

    const accessToken = response.data.token;
    const user = response.data.user;

    setSession(accessToken, setOpen);
    localStorage.setItem("accessToken", accessToken);

    localStorage.setItem("_id", user["_id"]);
    localStorage.setItem("teamId", user.team.teamId);
    localStorage.setItem("assignedTeamId", user.team.teamId);
    localStorage.setItem("teamName", user.team.teamName);
    localStorage.setItem("assignedTeamName", user.team.teamName);
    localStorage.setItem("teamLogo", user.team.teamLogo);
    localStorage.setItem("assignedTeamLogo", user.team.teamLogo);
    localStorage.setItem("permissions", user.role.permissions);
    localStorage.setItem("roleName", user.role.name);
    localStorage.setItem("profileImage", user.profileImage);
    localStorage.setItem("fullName", user.fullName);

    dispatch({
      type: "REGISTERVIAINVITE",
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null, setOpen);
    dispatch({ type: "LOGOUT" });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        registerViaInvite,
      }}
    >
      {children}
      {open && <SessionDialog open={open} setOpen={setOpen}/>}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
