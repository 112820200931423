import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import {LoadingButton} from "@mui/lab"
import { UploadAvatar, UploadMultiFile, UploadSingleFile, BlockContentPinata } from "../upload";
import { useCallback,useState, useEffect } from "react"
import axios from "axios"

RHFUploadAvatar.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadAvatar({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <div>
            <UploadAvatar error={checkError} {...other} file={field.value} />
            {checkError && (
              <FormHelperText error sx={{ px: 2, textAlign: "center" }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        );
      }}
    />
  );
}

RHFUploadSingleFile.propTypes = {
  name: PropTypes.string,
  accept: PropTypes.string,
};

export function RHFUploadSingleFile({ name, accept, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadSingleFile
            accept={accept}
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------
// Multiple uploads of files 
RHFUploadMultiFile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadMultiFile({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadMultiFile
            accept="image/*"
            files={field.value !== undefined ? field.value : []}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}
// ----------------------------------------------------------------------

const PINATA_JWT =process.env.REACT_APP_PINATA_JWT

export const PinataFolderUpload = ({ onUploadComplete, isImageCheck, pinataImageCId, isImage}) => {
 
  const [selectedFile, setSelectedFile] = useState();
  const [isUploading, setisUploading] = useState(false);
  const [fileCount, setFileCount] = useState(0); // New state variable for file count
  const [content, setContent] = useState(); // New state variable for file count
 
  
  const changeHandler = (event) => {
    const files = event.target.files;
    setSelectedFile(files);
    setFileCount(files.length); // Update file count
  };

  const sendData = async(formData) => {
    console.log("🎟️ formData", formData)

    try{
      const res = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
        maxBodyLength: "Infinity",
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          Authorization: 'Bearer ' + PINATA_JWT
        }
      });
      res.data.files = selectedFile;
      onUploadComplete(res.data);
    } catch (error) {
      console.log(error);
    } finally{
      setisUploading(false)
    }
  }


  const setData = (jsonData,formData,file) => {
      let jsonFilename = file['name'].split(".")[0];
      console.log("🎟️ jsonFilename", jsonFilename)
      let filteredArray = Array.from(pinataImageCId.files).filter(item => item['name'].split(".")[0] == jsonFilename);
      if(filteredArray.length > 0){
        jsonData['image'] = "ipfs://"+pinataImageCId.IpfsHash+"/"+filteredArray[0]['name'];
      }
      let fileEdited = new File([JSON.stringify(jsonData)],
            file['webkitRelativePath'],{"type" : "application/json"});
      formData.append("file",fileEdited);
      setContent(formData);
  }

  useEffect(() => {
    if (content!== undefined && !isImageCheck &&  pinataImageCId !== undefined && pinataImageCId !== null && isUploading){
       if(selectedFile.length == content.getAll("file").length ){
          sendData(content);
       }
      
    }
   
  }, [content,selectedFile,isImageCheck,pinataImageCId,sendData]);
  
  const reader = (file,formData) => {
    const fileReader = new FileReader();
  
    fileReader.onloadend =  () => {
        const jsonData = JSON.parse(fileReader.result);
        setData(jsonData,formData,file);
    };
      fileReader.readAsText(file);
  }

  

  const handleSubmission = async(event) => {
    setisUploading(true)
    event.preventDefault();

    const formData = new FormData();
    let jsonFiles = false;
    const metadata = JSON.stringify({name: 'Folder name'});
    formData.append('pinataMetadata', metadata);

    const options = JSON.stringify({cidVersion: 1})
    formData.append('pinataOptions', options);
    
    Array.from(selectedFile).forEach((file) => {
        // Skip .DS_Store files
        if (file.name === ".DS_Store") {
            console.log("Skipping .DS_Store file");
            return; // Continue to the next file
        }
        
        if (!isImage && file['type'].indexOf("json") != -1){
            if(isImageCheck){
                formData.append("file", file);
            } else if(!isImageCheck &&  pinataImageCId !== undefined 
              && pinataImageCId !== null){
                reader(file,formData);
                jsonFiles = true; 
            }
           
        } else if(isImage && file['type'].indexOf("image") !== -1){
            formData.append("file", file);
            console.log(file);
        } 
    })
    console.log(formData);
    if(!jsonFiles){
        sendData(formData);
    }
};

  return (
    <>
      <BlockContentPinata onChangeHandler={changeHandler} />

      {fileCount > 0 && ( // Conditionally render the file count
        <div style={{ marginTop: '8px' }}>
          Number of items in folder: {fileCount} items
        </div>
      )}

      {/* <button onClick={handleSubmission} style={{ marginTop: '8px' }}>
            Submit
      </button> */}
      {fileCount > 0 && (
      <LoadingButton
        variant="contained"
        size="small"
        style={{ marginTop: '8px', marginBottom: '8px' }}
        loading={isUploading}
        onClick={handleSubmission}
      >
        Upload Folder
      </LoadingButton>
      )}
    </>
  );
}