import {
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  IconButton,
} from "@mui/material";
import Iconify from "src/components/Iconify";

export function SessionDialog({open, setOpen}) {
  
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog fullWidth maxWidth="xs" open={open} onClose={() => handleClose()}>
        <DialogTitle>Session Warning
        {<IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Iconify icon={"material-symbols:close"} width={25} height={25} />
            </IconButton>}
        </DialogTitle>
        <DialogContent><Alert severity="warning">Your session will expire in 5 minutes. Please save any outstanding changes to avoid losing your progress.</Alert></DialogContent>
      </Dialog>

    </>
  );
}
