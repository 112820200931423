import { useLocation, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Button, AppBar, Toolbar, Container, Typography, Menu, MenuItem, Stack, IconButton, Divider, Modal, Backdrop} from "@mui/material";
import Iconify from "src/components/Iconify";
import useOffSetTop from "../../hooks/useOffSetTop";
import useResponsive from "../../hooks/useResponsive";
import cssStyles from "../../utils/cssStyles";
import { HEADER } from "../../config";
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import Image from "src/components/Image";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import CurrentMarketplace from "./MenuConfig";
import ReactGA from "react-ga";
import axios from "axios";
import { PATH_PAGE } from "src/routes/paths";

import {
        ConnectWallet,
        useAddress,
        useWallet,
        useSigner,
        useBalance, useChainId,
        useDisconnect,
        useConnectionStatus,
        useEmbeddedWalletUserEmail } from "@thirdweb-dev/react";



export const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(["height", "background-color"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("md")]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

export const ToolbarShadowStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

MainHeader.propTypes = {
  component: PropTypes.array,
};

const ModalBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};
const ContentBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};


export default function MainHeader({
  marketplace,
  component,
  marketplaceId,
  marketplaceUrlName,
  mainColor,
  accentColor,
  fileUrl,
  wallet,
  walletEmail,
  setWallet,
  setWalletEmail,
  sig,
  setSig,
}) {

  const BackdropStyle = {
    // Apply blur effect here
    backdropFilter: 'blur(2px)',
    // You can also apply a color overlay with reduced opacity
    backgroundColor: 'rgba(0, 0, 0, 0.01)',
  };

  
  // heirloom start]
  const [isLoading, setIsLoading] = useState(false);
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const theme = useTheme();

  const { pathname } = useLocation();

  const disconnect = useDisconnect();
  
  const [openModal, setOpenModal] = useState(false);


  const walletInstance = useWallet();
  const address  = useAddress();
  const signer  = useSigner();
  const connectionStatus = useConnectionStatus();
  const emailQuery = useEmbeddedWalletUserEmail();
  const navigate = useNavigate();

  const isDesktop = useResponsive("up", "md");

  const isHome = pathname === "/";

  const [marketplaceName, setMarketplaceName] = useState("");
  const [logoPosition, setLogoPosition] = useState("");

  const balance = useBalance();
  const chainID = useChainId();
  // State to manage balance and loading state
  const [walletBalance, setWalletBalance] = useState(null);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [currency, setCurrency] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  function generateWalletURL(chain, address, environment) {
    if (chain === "Ethereum") {
      return "https://etherscan.io/address/" + address;
    } else if (chain === "Polygon") {
      return "https://polygonscan.com/address/" + address;
    }
  }

  const ButtonStyle = styled(Button)(({ theme }) => ({
    ...theme.palette,
    textTransform: "capitalize",
    backgroundColor: accentColor,
    "&:hover": {
      backgroundColor: mainColor,
      color: "#ffffff",
    },
    color: "#ffffff",
  }));


  useEffect(() => {
    // console.log("walletInstance", walletInstance)
    // console.log("🔴 connectionStatus", connectionStatus)
    // console.log("🔴 wallet", wallet)
    // console.log("🔴 emailQuery.data", emailQuery.data)
    if (connectionStatus === "connected" && (wallet == null || wallet === "") && (sig == null || sig == "")) {
      if(address){
         requestAccountThird(address);
      } else {
        console.error("🔴 address is null");
      }
    }
  })
  async function logActivity(userId, activityType, screen, routePath, metadata, additionalNotes) {
    const postData = {
      userId,
      activityType,
      screen,
      routePath,
      metadata,
      additionalNotes
    };
  
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/passport/logActivity`, postData)
      .then(response => {
        console.log('Activity logged successfully:', response.data);
      })
      .catch(error => {
        console.error('Error logging activity:', error);
      });
}

  async function requestAccountThird(connectedWallet) {
    setWallet(connectedWallet.toLowerCase());
    setWalletEmail(emailQuery.data);

      // Check if the modal has already been shown
    
    const hasShownModal = localStorage.getItem('hasShownModal');
    const storedSigner = localStorage.getItem('storedSigner');
   // dont know how to get email
   /* if (walletProvider[2] !== null) {
      setWalletEmail(walletProvider[2]);
    }*/

    //POST wallet
    try {
      // console.log("🔴 wallets post  data", {
      //   walletAddress: connectedWallet.toLowerCase(),
      //   marketplaceId: marketplaceId,
      //   email: emailQuery.data,
      // })

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/wallets`, {
        walletAddress: connectedWallet.toLowerCase(),
        marketplaceId: marketplaceId,
        email: emailQuery.data,
        source: walletInstance.walletId,
        status: "connected wallet"
      });

      logActivity({
        userId: '',
        activityType: 'Wallet Connected',
        screen: 'MainHeader',
        routePath: '/marketplaces',
        metadata:{
          wallet: connectedWallet.toLowerCase(),
          email: emailQuery.data,
        },
        additionalNotes: "User connected a new wallet"
      })

      if (!hasShownModal) {
        setOpenModal(true);
        // Set the flag in localStorage
        localStorage.setItem('hasShownModal', 'true');
      }
    } catch (error) {
      //setError(true);
    }

    if (emailQuery.data == undefined && storedSigner == null){
        try {
          var signature = await signer.provider.send('personal_sign', [
            process.env.REACT_APP_SIGNING_MESSAGE,
            connectedWallet
          ]);
          localStorage.setItem('storedSigner', signature);
          setSig(signature);
        } catch (e) {
          //If signature rejected
          console.log(e);
          await disconnect();
          setWallet(null);
          setWalletEmail(null);
          setSig(null);
        }
    }

    ReactGA.event({
      category: `MARKETPLACE-${marketplaceId}`,
      action: "MARKETPLACE_HEADER_CONNECT_WALLET",
      label: `marketplaceId: ${marketplaceId}, walletId: ${connectedWallet.toLowerCase()}`,
    });
  }

  // Log balance for debugging
  useEffect(() => {
    if (!balance.isLoading && balance?.data) {
      setWalletBalance(balance.data.displayValue)
      console.log("Wallet balance:", balance.data.displayValue); // Adjust according to how you want to use/display balance
    }
  }, [balance, balance.isLoading]);

  useEffect(() => {
    async function fetchExchangeRate() {
      try {
        const _currency = chainID === 80001 || chainID === 137 ? "MATIC" : "ETH";
        setCurrency(_currency)
        const response = await axios.get(`https://api.coinbase.com/v2/exchange-rates?currency=${_currency}`);
        const rate = response.data.data.rates.USD;
        setExchangeRate(rate);
      } catch (error) {
        console.error("Failed to fetch exchange rate:", error);
        setExchangeRate(null); // Handle error state appropriately
      }
    }
  
    fetchExchangeRate();
  }, [chainID]);


  const logout = async () => {
     await disconnect();
     setWallet(null);
    setWalletEmail(null);
    setSig(null);
    navigate(marketplaceUrlName !== "" ? PATH_PAGE.marketplaceView(marketplaceUrlName) : PATH_PAGE.marketplaceView(marketplaceId));
    return;
  };

  function formatWallet(walletString) {
    console.log("walletString", walletString)
    return walletString.slice(0, 4) + "..." + walletString.slice(-4);
  }
  function formatBalance(balance, rate) {
    let fixedPrecision = balance === 0 ? 1 : balance * 10 % 1 === 0 ? 2 : 3;
    let formattedBalance = Number(balance).toFixed(fixedPrecision);
    let formattedValue = (Number(balance) * Number(rate)).toFixed(2);
    return `${formattedBalance} ${currency} ~ $(${formattedValue})`;
  }

  // function formatExchangeRate(balance, rate) {
  //   const numBalance = Number(balance);
  //   const numRate = Number(rate);
  //   return (numBalance * numRate).toFixed(2);
  // }

  const handleModalClose = () =>  {
    setOpenModal(false);
  }

  useEffect(() => {
    getHeaderData(component);
  }, [component, mainColor, accentColor, fileUrl]);

  function getHeaderData(component) {
    if (component !== undefined) {
      const name = component[0].attributes.filter(function (el) {
        return el.key === "name";
      });
      setMarketplaceName(name[0].value);
      const logoPlacement = component[0].attributes.filter(function (el) {
        return el.key === "logoPosition";
      });
      setLogoPosition(logoPlacement[0].value);
    }
  }

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: "transparent" }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        {logoPosition === "left" && (
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Image
              alt="marketplace logo"
              src={fileUrl}
              sx={{ mb: 0, mt: 2, mx: "auto", width: 65, height: 65 }}
            />
            <Typography variant="subtitle1" color={mainColor} sx={{ ml: 1 }}>
              {marketplaceName}
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            {isDesktop && marketplaceId !== null && (
              <MenuDesktop
                isOffset={isOffset}
                isHome={isHome}
                navConfig={CurrentMarketplace(marketplaceId, marketplaceUrlName)}
                marketplaceId={marketplaceId}
                mainColor={mainColor}
                accentColor={accentColor}
              />
            )}

            {wallet === null && (
             <ConnectWallet
                theme={"light"}
                modalSize={"wide"}
                btnTitle="Enter"
                auth={{loginOptional: true}}
                style={{ backgroundColor: mainColor, padding: '.5rem 4px'}} 
                modalTitle={"Keyspace"}
                modalTitleIconUrl={""}
                welcomeScreen={{
                  title: "Connect wallet to get started",
                  subtitle:
                    "If you don't have a wallet, click below",
                }}
                showThirdwebBranding={false}
            />
          )}

          {wallet !== null && wallet !== undefined && wallet !== "" && (
            <a
              target="_blank"
              rel="noreferrer"
              href={generateWalletURL("Ethereum", wallet)}
            >
              <Typography variant="body2" color={mainColor} sx={{ ml: 1 }}>
                <span>{walletEmail !== null ? walletEmail : formatWallet(wallet)}</span>
                {/* <span style={{ marginLeft: '8px' }}>{formatBalance(walletBalance, exchangeRate)}</span> */}
                {/* <span style={{ marginLeft: '4px' }}>{currency}</span> */}
              </Typography>
            </a>
          )}

            {wallet !== null && wallet !== undefined && wallet !== "" && (
              <IconButton sx={{ ml: 1 }} onClick={handleClick}>
                <Iconify
                  icon={"material-symbols:account-circle"}
                  width={25}
                  height={25}
                />
              </IconButton>
            )}

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <Stack direction="row">
                  <Typography
                    onClick={() =>
                      navigate(marketplaceUrlName !== "" ? PATH_PAGE.marketplaceProfile(marketplaceUrlName) : PATH_PAGE.marketplaceProfile(marketplaceId))
                    }
                  >
                    My Profile
                  </Typography>
                </Stack>
              </MenuItem>
              <Divider />
              <MenuItem>
                <Stack direction="row">
                  <Typography onClick={() => logout()}>Logout</Typography>
                </Stack>
              </MenuItem>
            </Menu>

            {!isDesktop && (
              <MenuMobile
                isOffset={isOffset}
                isHome={isHome}
                navConfig={CurrentMarketplace(marketplaceId, marketplaceUrlName)}
                fileUrl={fileUrl}
                mainColor={mainColor}
                accentColor={accentColor}
                marketplaceId={marketplaceId}
              />
            )}
          </Container>
        )}
        {logoPosition === "right" && (
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {wallet === null && (
             <ConnectWallet
                  theme={"light"}
                  modalSize={"wide"}
                  btnTitle="Enter"
                  style={{ backgroundColor: mainColor, padding: '.5rem 4px'}} 
                  modalTitle={"Keyspace"}
                  modalTitleIconUrl={""}
                  welcomeScreen={{
                    title: "Connect wallet to get started",
                    subtitle:
                      "If you don't have a wallet, click below",
                  }}
                  showThirdwebBranding={false}
              />
            )}
            {wallet !== null && wallet !== undefined && wallet !== "" && (
              <Typography variant="body2" color={mainColor} sx={{ ml: 1 }}>
                {walletEmail !== null ? walletEmail : formatWallet(wallet)}
              </Typography>
            )}
            {wallet !== null && wallet !== undefined && wallet !== "" && (
              <IconButton sx={{ ml: 1, mr: 1 }} onClick={handleClick}>
                <Iconify
                  icon={"material-symbols:account-circle"}
                  width={25}
                  height={25}
                />
              </IconButton>
            )}

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <Stack direction="row">
                  <Typography
                    onClick={() =>
                      navigate(marketplaceUrlName !== "" ? PATH_PAGE.marketplaceProfile(marketplaceUrlName) : PATH_PAGE.marketplaceProfile(marketplaceId))
                    }
                  >
                    My Profile
                  </Typography>
                </Stack>
              </MenuItem>
              <Divider />
              <MenuItem>
                <Stack direction="row">
                  <Typography onClick={() => logout()}>Logout</Typography>
                </Stack>
              </MenuItem>
            </Menu>

            {isDesktop && (
              <MenuDesktop
                isOffset={isOffset}
                isHome={isHome}
                navConfig={CurrentMarketplace(marketplaceId, marketplaceUrlName)}
                marketplaceId={marketplaceId}
                mainColor={mainColor}
                accentColor={accentColor}
              />
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Typography variant="subtitle1" color={mainColor} sx={{ mr: 1 }}>
              {marketplaceName}
            </Typography>
            <Image
              alt="marketplace logo"
              src={fileUrl}
              sx={{ mb: 0.5, mt: 2, mx: "auto", width: 65, height: 65 }}
            />

            {!isDesktop && (
              <MenuMobile
                isOffset={isOffset}
                isHome={isHome}
                navConfig={CurrentMarketplace(marketplaceId, marketplaceUrlName)}
                fileUrl={fileUrl}
                mainColor={mainColor}
                accentColor={accentColor}
                marketplaceId={marketplaceId}
              />
            )}
          </Container>
        )}

        {logoPosition === "center" && (
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {isDesktop && (
              <MenuDesktop
                isOffset={isOffset}
                isHome={isHome}
                navConfig={CurrentMarketplace(marketplaceId, marketplaceUrlName)}
                marketplaceId={marketplaceId}
                mainColor={mainColor}
                accentColor={accentColor}
              />
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Image
              alt="marketplace logo"
              src={fileUrl}
              sx={{ mb: 0.5, mt: 2, mx: "auto", width: 65, height: 65 }}
            />
            <Box sx={{ flexGrow: 1 }} />
            {wallet === null && (
         <ConnectWallet
                  theme={"light"}
                  modalSize={"wide"}
                  btnTitle="Enter"
                  style={{ backgroundColor: mainColor, padding: '.5rem 4px'}} 
                  modalTitle={"Keyspace"}
                  modalTitleIconUrl={""}
                  welcomeScreen={{
                    title: "Connect wallet to get started",
                    subtitle:
                      "If you don't have a wallet, click below",
                  }}
                  showThirdwebBranding={false}
              />
          )}
            {wallet !== null && wallet !== undefined && wallet !== "" && (
              <Typography variant="body2" color={mainColor} sx={{ ml: 1 }}>
                {walletEmail !== null ? walletEmail : formatWallet(wallet)}
              </Typography>
            )}
            {wallet !== null && wallet !== undefined && wallet !== "" && (
              <IconButton sx={{ ml: 1 }} onClick={handleClick}>
                <Iconify
                  icon={"material-symbols:account-circle"}
                  width={25}
                  height={25}
                />
              </IconButton>
            )}

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <Stack direction="row">
                  <Typography
                    onClick={() =>
                      navigate(marketplaceUrlName !== "" ? PATH_PAGE.marketplaceProfile(marketplaceUrlName) : PATH_PAGE.marketplaceProfile(marketplaceId))
                    }
                  >
                    My Profile
                  </Typography>
                </Stack>
              </MenuItem>
              <Divider />
              <MenuItem>
                <Stack direction="row">
                  <Typography onClick={() => logout()}>Logout</Typography>
                </Stack>
              </MenuItem>
            </Menu>

            {!isDesktop && (
              <MenuMobile
                isOffset={isOffset}
                isHome={isHome}
                navConfig={CurrentMarketplace(marketplaceId, marketplaceUrlName)}
                fileUrl={fileUrl}
                mainColor={mainColor}
                accentColor={accentColor}
                marketplaceId={marketplaceId}
              />
            )}
          </Container>
        )}
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}


      <Modal
        open={openModal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          sx: BackdropStyle,
        }}
      >
        <Box sx={ModalBoxStyle}>
          <Image
            alt="marketplace logo"
            src={fileUrl}
            sx={{ mb: 0.5, mt: 2, mx: "auto", width: 65, height: 65 }}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            {/* Congrats your wallet has been saved, we will notify you when you’ve received your NFT. */}
            Congrats You are now logged in!
          </Typography>
          <Button
            sx={{ mt: 0 }}
            variant="contained"
            color="primary"
            onClick={handleModalClose}
          >
            Close
          </Button>
        </Box>
      </Modal>

    </AppBar>
  );
}