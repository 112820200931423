import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  IconButton,
  InputAdornment,
  Alert,
  Typography,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import Iconify from "../../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";

export default function RegisterViaInviteForm() {
  const [error, setUrlParamsError] = useState(false);
  const { registerViaInvite } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const inviteEmail = urlParams.get("email");
  const inviteKey = urlParams.get("key");

  useEffect(() => {
    if (inviteKey == null || inviteEmail === null) {
      setUrlParamsError(true);
    }
  }, [inviteKey, inviteEmail]);

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required("First name required"),
    lastName: Yup.string().required("Last name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    terms: Yup.boolean()
      .oneOf([true], "The terms and conditions must be accepted")
      .required("The terms and conditions must be accepted"),
    key: Yup.string(),
  });

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: inviteEmail || "",
    phone: "",
    password: "",
    terms: false,
    key: inviteKey,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await registerViaInvite(
        data.email,
        data.phone,
        data.password,
        data.firstName,
        data.lastName,
        data.terms,
        data.key
      );
    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError("afterSubmit", { ...error, message: error });
      }
    }
  };

  return (
    <>
      {!error && (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            {!!errors.afterSubmit && (
              <Alert severity="error">Failed to complete registration.</Alert>
            )}

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <RHFTextField name="firstName" label="First name" />
              <RHFTextField name="lastName" label="Last name" />
            </Stack>

            <RHFTextField
              name="email"
              label="Email address"
              disabled={!!inviteEmail}
            />
            <RHFTextField
              name="phone"
              placeholder="111-111-1111"
              label="Phone"
            />

            <RHFTextField
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant="body2">
              Password must be at least 6 characters
            </Typography>
            <RHFCheckbox
              name="terms"
              label={
                <Typography
                  variant="body2"
                  align="left"
                  sx={{ color: "text.secondary", mt: 3 }}
                >
                  By registering, I agree to KeySpace&nbsp;
                  <Link underline="always" color="text.primary" href="#">
                    Terms of Service
                  </Link>
                  {""} and {""}
                  <Link underline="always" color="text.primary" href="#">
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              }
              sx={{ mt: 3 }}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Register
            </LoadingButton>
          </Stack>
        </FormProvider>
      )}
      {error && (
        <Stack direction="row">
          <Iconify
            icon={"ant-design:exclamation-circle-outlined"}
            width={40}
            height={40}
            sx={{ mr: 1, color: "red" }}
          />
          <Typography>
            Oops! Looks like the requested invitation is invalid. Please try
            again.
          </Typography>
        </Stack>
      )}
    </>
  );
}
