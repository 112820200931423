import { Link as RouterLink } from "react-router-dom";
import { Box, Stack, Link, Container, Typography } from "@mui/material";
import { PATH_AUTH } from "../../routes/paths";
import useResponsive from "../../hooks/useResponsive";
import Page from "../../components/Page";
import Logo from "../../components/Logo";
import Image from "../../components/Image";
import { LoginForm } from "../../sections/auth/login";
import { ContentStyle } from "./ResetPassword";
import { HeaderStyle, SectionStyle, RootStyle } from "./Register";

export default function Login() {
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {""}
              <Link
                variant="subtitle2"
                component={RouterLink}
                to={PATH_AUTH.register}
              >
                Get started
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 13, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <Image
              visibleByDefault
              disabledEffect
              src="/assets/keyspace/login.png"
              alt="login"
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in to Keyspace Studio
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Enter your details below.
                </Typography>
              </Box>
            </Stack>

            <LoginForm />

            {/*{!smUp && (*/}
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?{" "}
              <Link
                variant="subtitle2"
                component={RouterLink}
                to={PATH_AUTH.register}
              >
                Get started
              </Link>
            </Typography>
            {/*)}*/}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
