// @mui
import { Box, Typography, Stack } from "@mui/material";
// assets
import { UploadIllustration } from "../../assets";

// ----------------------------------------------------------------------

export default function BlockContentPinata({ onChangeHandler }) {
  return (
    <label style={{ width: '100%', cursor: 'pointer' }}>
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction={{ xs: "column", md: "row" }}
        sx={{
          width: 1, textAlign: { xs: "center", md: "left" }, p: 3,
          border: '1px dashed grey', borderRadius: 2,
          ':hover': { backgroundColor: 'rgba(0,0,0,0.05)' } // Optional: change on hover
        }}
      >
        <UploadIllustration sx={{ width: 220 }} />

        <Box sx={{ width: '100%' }}>
          <Typography gutterBottom variant="h5">
            Drop or Select Folder
          </Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Drop files here or click&nbsp;<span style={{ color: "primary.main", textDecoration: "underline" }}>browse</span>&nbsp;through your machine
          </Typography>

          {/* Hidden Input Field */}
          <input
            directory=""
            webkitdirectory=""
            type="file"
            onChange={onChangeHandler}
            style={{ display: 'none' }}
          />
        </Box>
      </Stack>
    </label>
  );
}

